

.hovare img:hover {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.shrink img {
  transition: 1s ease;
  }
  
  .shrink img:hover{
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  transition: 1s ease;
  }
  

.psluxuyhotel{
  background-color: rgb(204, 100, 14);
  font-family: 'Ubuntu', sans-serif;
  padding: 14px;
}

.psluxuyhotel{
  color: white;
  
}


.psluxuryhotel{
  font-family: 'Ubuntu', sans-serif;
}

.radiusboook img{
  border-radius: 38px;
}

.btn-grad20 {
  background-image: linear-gradient(to right, #c95b16 0%, #010100  51%, #b7a238  100%);
  margin: 0px;
  padding: 9px 29px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 12px;
  display: block;
}

.btn-grad20:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}