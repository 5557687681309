

.tagcolor Link{
    color: red;

}

.psluxury {
    background-color: rgb(119, 59, 2);
    padding: 2px;
    font-family: 'Croissant One', cursive;
}


.headlogo{
    margin-left: 25px;
    display: flex;
    width:210px;
    height: 50px;
}
.logandsign{
    margin-left: 20px;
    padding: 25px;
     
    /* padding-right: 10px;
    letter-spacing: 1.5px; */
}
/* .first{
    margin:02px 03px;
    padding: 0 20px;
    border: 1px solid #46c4d4;
    border-radius: 25px;
    cursor: pointer;
    overflow: hidden;
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);   
} */
.first :hover {
    transform: scale(1.05);
}
/* .for_providers{
    font-size: 20px;
    margin-top: 20px;
    margin-left: 20px;
} */


.logosider{
  margin-left: 10px;
}

.text {
    font-family: 'Ubuntu', sans-serif;
}

.buttonlogin{
    background-color: #d7d4e6; /* Green */
    border: none;
    color: white;
    border-radius: 12px;
    padding: 7px 17px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
}


.mrmondalanalytics{
    background-color: aliceblue;
}

.buttonbook{
    background-color: rgb(235, 242, 247); /* Green */
    border: none;
    color: white;
    border-radius: 12px;
    padding: 4px 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
}






/* Dropdown Button */
.dropbtn {
    background-color: rgb(55, 55, 58);
    color: white;
    padding: 8px;
    font-size: 16px;
    border: none;
     border-radius: 14px;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: rgb(42, 38, 38) 64, 64;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {background-color: rgb(7, 7, 8);}






.dropbtn{
    color: gold;
}

