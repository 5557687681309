
.luxuryhotelberhampore{
    background-color: #0E151F;
}

.review{
    background-color: #953616; /* Green */
    border: none;
    color: rgb(255, 255, 255);
    border-radius: 12px;
    padding: 9px 45px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
}