.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.container{
  font-family: 'Ubuntu', sans-serif;
}

.imagesc1 img{
  border-radius: 45px;
}

.buttontime{
  background-color: #aa6d04; /* Green */
  border: none;
  color: white;
  padding: 15px 70px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
}

.backg{
  background-color: #282c34;
  padding: 15px 25px;
}

a {
  text-decoration: none !important;

  
}

.buttonroombook{
  background-color: #cb9941; /* Green */
  border: none;
  color: white;
  padding: 15px 70px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
}

.backc1{
  background-color: rgb(80, 216, 194);
  padding: 50px;
  text-emphasis-color: red;
}









.header .container {
  justify-content: center;
}
.input-group-text {
  background-color: #212529;
  color: #fff;
  font-weight: bold;
}
.edit,
.delete {
  padding: 0px 6px;
  border-radius: 2px;
}

.edit {
  margin-right: 10px;
}
table {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}