

.imagesc {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
  }
  
  /* On mouse-over, add a deeper shadow */
  .imagesc:hover {
    box-shadow: 0 15px 32px 0 rgba(0,0,0,0.2);
  }
  

  .back{
    background-color: rgb(255, 238, 227);
  }
